/****************************************

			Theme Styles

****************************************/



/********** Typography **********/

html {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	letter-spacing: 0.05em;
	user-select: none;
}

.site-content {
	hyphens: auto;
}

.less-lineheight {
	line-height: 1.1em;
}




/********** Header & Navigation **********/

header.navbar {
	padding-left: $grid-gutter-width / 4;
	padding-right: $grid-gutter-width / 4;

		/* @include media-breakpoint-up(sm) {
			padding-left: $grid-gutter-width / 2;
			padding-right: $grid-gutter-width / 2;
		} */

	box-shadow: rgba(0, 0, 0, 0.05) 0px 8px 16px 0px, rgba(0, 0, 0, 0.1) 0px -1px 0px 0px inset;
	padding-top: 0;
	padding-bottom: 0;
	min-height: 56px;



	a.navbar-brand {
		display: flex;
		flex-direction: column;
		justify-content: center;

		svg {
			width: 140px;
			height: auto;

			/* @include media-breakpoint-up(sm) {
				width: 140px;
			} */
		}

		h2 {
			margin: 0;
		}
	}

	nav {
		ul {
			li.nav-item {
				/* margin-left: 4px;
				margin-right: 4px; */
				
				@include media-breakpoint-up(sm) {
					padding: 0 0.25em;
				}

				a {
					text-decoration: none;

					&:hover {
						cursor: pointer;
					}
					&:active {
					}
					&:focus {
						outline: 0;
					}
				}

				.badge-pill {
					font-size: 10.5px;
					line-height: 1.3;
					display: inline-block;
					width: 20px;
					height: 20px;
					vertical-align: middle;
				}

				.list-group-item {
					.badge-pill {
						margin-top: -2px;
						line-height: 1.5;
					}
				}
			}
		}
	}
}

.site-content {
	min-height: 100vh;
	padding-top: 56px + $grid-gutter-width/4;
	padding-bottom: $grid-gutter-width/4;

	@include media-breakpoint-up(sm) {
		padding-top: 56px + $grid-gutter-width/2;
		padding-bottom: $grid-gutter-width/2;
	}
}


/********** Login Page **********/

body.login {
	background: $body-bg url(../../assets/swissimmo_winkelried.jpg) center center / cover no-repeat;
	
	.site-content {
		display: flex;
		align-items: center;

		.form-signin {
			width: 100%;
			max-width: 420px;
			padding: $grid-gutter-width;
			margin: auto;
			background-color: rgba($white, 0.85);
			backdrop-filter: blur(5px);
			border-radius: 0.25rem;
			position: relative;
			box-shadow: rgba(0, 0, 0, 0.05) 0px 8px 16px 0px, rgba(0, 0, 0, 0.1) 0px -1px 0px 0px inset;

			.signin-logo-container {
				position: absolute;
				left: 0;
				right: 0;
				top: -40px;
				width: 80px;
				height: 80px;
				border-radius: 50%;
				margin: 0 auto;
				background-color: $deep-black;
				display: flex;
				justify-content: center;
				align-items: center;
				box-shadow: rgba(0, 0, 0, 0.05) 0px 8px 16px 0px, rgba(0, 0, 0, 0.1) 0px -1px 0px 0px inset;

				svg {
					width: 80px;
					height: 80px;
				}
			}

			h2 {
				margin-top: -0.5em;

			}
		}
	}
}







/********** CSS Grid for Modules **********/

.container-fluid {
	padding-left: $grid-gutter-width / 4;
	padding-right: $grid-gutter-width / 4;

	@include media-breakpoint-up(sm) {
		padding-left: $grid-gutter-width / 2;
		padding-right: $grid-gutter-width / 2;
	}

	.grid-wrapper {
		display: grid;
		grid-gap: $grid-gutter-width / 4 !important;

		@include media-breakpoint-up(sm) {
			grid-gap: $grid-gutter-width / 2 !important;
		}
		grid-template-columns: 1fr;
		/* grid-auto-rows: 1fr; */
		grid-auto-columns: 1fr;
		grid-auto-flow: row dense;

		@include media-breakpoint-up(md) {
			grid-template-columns: 1fr 1fr;

			.span-2-columns {
				grid-column: span 2;
			}
			.span-3-columns {
				grid-column: span 2;
			}
			.span-4-columns {
				grid-column: span 2;
			}
			.span-2-rows {
				grid-row: span 2;
			}
			.span-3-rows {
				grid-row: span 3;
			}
		}

		@media (min-width: 1440px) {
			grid-template-columns: 1fr 1fr 1fr;

			.span-3-columns {
				grid-column: span 3;
			}
			.span-4-columns {
				grid-column: span 3;
			}
		}

		@media (min-width: 1920px) {
			grid-template-columns: 1fr 1fr 1fr 1fr;

			.span-4-columns {
				grid-column: span 4;
			}
		}

		.card {
			color: $dark-gray;
			/* border-radius: 0px; */
			border: 0;
			background-color: rgba($white, 1);
			/* padding: 0; */
			min-height: 220px;
			height: 100%;
			box-shadow: rgba(0, 0, 0, 0.05) 0px 8px 16px 0px, rgba(0, 0, 0, 0.1) 0px -1px 0px 0px inset;

			&.no-minheight {
				min-height: 0;
			}

			.btn.active.focus,
			.btn.active:focus,
			.btn.focus,
			.btn.focus:active,
			.btn:active:focus,
			.btn:focus {
				outline: 0;
				outline-offset: 0;
				background-image: none;
				-webkit-box-shadow: none;
				box-shadow: none;
			}

			.carousel.slide,
			.carousel-fade.slide {
				box-shadow: none;
			}

			.card-header {
				position: relative;
				background-color: transparent;
				border: 0;
				padding-top: 1.25em;
				padding-bottom: 0;

				@include media-breakpoint-up(lg) {
					padding: $grid-gutter-width/2;
					padding-bottom: 0;
					/* margin-bottom: -$grid-gutter-width/4; */
				}

				:last-child {
					margin-bottom: 0;
				}

				.tools {
					margin-left: 0.5em;

					a {
						&:visited,
						&:focus,
						&:hover,
						&:active {
							text-decoration: none;
						}
					}

				}

				/* a {
					padding: 1.3em;
					position: absolute;
					right: 0;
					top: 0;
				} */

				.card-title {
					color: $black;
					font-size: 1em;
					text-transform: uppercase;
					font-weight: 500;
					letter-spacing: 0.1em;
					/* padding-right: 1.3em; */
				}

				.card-subtitle {
					font-weight: 400;
					/* padding-right: 1.3em; */
					font-size: 0.875em;
				}
			}

			.card-body {

				@include media-breakpoint-up(lg) {
					padding: $grid-gutter-width/2;
				}

				:last-child {
					margin-bottom: 0;
				}

				.row > div.statistics {
					@include media-breakpoint-up(md) {
						border-right: 0.5px solid rgba($light-gray, 0.75);
						border-left: 0.5px solid rgba($light-gray, 0.75);

						&:nth-child(3n+1) {
							border-left: none;
						}
					}

					.statistics-inner {
						padding: 1em 0;

						/* @include media-breakpoint-up(md) {
							padding: 0;
						} */
					}
				}

				.row  {
					div.statistics {
						@include media-breakpoint-up(md) {
							&:nth-child(3n+0) {
								border-right: none;
								
							}
						}
						@include media-breakpoint-up(xl) {
							&:nth-child(3n+0) {
								border-right: 0.5px solid rgba($light-gray, 0.75);
							}
							&:nth-child(1n+0) {
								border-left: 0.5px solid rgba($light-gray, 0.75);
							}
							&:last-child {
								border-right: none;
							}
							&:first-child {
								border-left: none;
							}
						}
					}
				}

				.signin-logo-container {
					width: 60px;
					height: 60px;
					margin: 0 auto;

					svg {

					}
				}
			}

			.card-footer {
				background-color: transparent;
				border: 0;
				padding-bottom: 1.25em;
				padding-top: 0;

				@include media-breakpoint-up(lg) {
					padding: $grid-gutter-width/2;
					padding-top: 0;
				}
			}

			.nav-tabs {
				border: 0;

				.nav-item {

					.nav-link {
						/* border-radius: 0; */
						border: 0;
					}
				}
			}

			hr {
				border-color: rgba($light-gray, 1);
			}

			.btn.btn-outline-light {
				&:hover, &:active, &:focus {
					color: $dark-gray;
				}
			}

			.list-group-item {
				/* padding-left: 30px;
				padding-right: 30px; */
			}

			&.card-bg-primary {
				background-color: rgba($primary, 1);
				color: $white;
				.card-title {
					color: $deep-black;
				}
				.btn.btn-outline-light {
					&:hover, &:active, &:focus {
						color: $primary;
					}
				}
				a {
					color: $white;
				}
			}

			&.card-bg-secondary {
				background-color: rgba($secondary, 1);
				color: $deep-black;
				.card-title {
					color: $deep-black;
				}
				.btn.btn-outline-light {
					&:hover, &:active, &:focus {
						color: $secondary;
					}
				}

				a {
					color: $deep-black;
				}
			}

			&.card-bg-tertiary {
				background-color: rgba($tertiary, 1);
				color: $white;
				.card-title {
					color: $white;
				}
				.btn.btn-outline-light {
					&:hover, &:active, &:focus {
						color: $tertiary;
					}
				}

				a {
					color: $white;
				}
			}
		}

		.carousel.slide, .carousel-fade.slide {
			height: 100%;
			border: 0;
			box-shadow: rgba(0, 0, 0, 0.05) 0px 8px 16px 0px, rgba(0, 0, 0, 0.1) 0px -1px 0px 0px inset;
			border-radius: 0.25rem;

			.carousel-inner {
				height: 100%;
				border: 0;

				.carousel-item, .active {
					height: 100%;
					border: 0;

					.card {
						box-shadow: none;
					}
				}
			}
			&[class*="bg-"] {
				[class*="card-bg-"] {
					background-color: 'transparent';
				}
			}
		}
	}
}






.card-img-background {
	background-size: cover;
	background-position: center center;
}

.card-img-top {
	/* border-radius: 0; */
}

.card-img-overlay {
	padding: 0;
	position: relative;
	height: 100%;
	background: linear-gradient(180deg, rgba(theme-color-level(tertiary, 0), 0.95) 50%, rgba(theme-color-level(tertiary, 0), 0.475) 100%);
	backdrop-filter: blur(1px);
	width: 50%;
	color: $white;
	border-top-left-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;

	/* padding: 30px; */

	.card-body {
		padding-right: 10px !important;
	}

	.btn.btn-outline-white {
		&:hover, &:active, &:focus {
			color: $tertiary;
		}
	}
}

.card-body {
	form {
		input, textarea, .form-control:focus {
			background-color: rgba($white, 1);
			color: $dark-gray;
			border-color: $dark-gray;
			outline: none;
			box-shadow: none;
			resize: none;
		}

		.form-control {
			&::placeholder {
			color: rgba($dark-gray, 1);
			vertical-align: bottom;
		}

		&:focus::placeholder {
				color: rgba($light-gray, 1);
			}
		}
	}
}


.card-bg-primary, .card-bg-secondary, .card-bg-tertiary {
	form {
		input, textarea, .form-control:focus {
			border: 0;
		}

		.custom-radio .custom-control-label::before {
			background-color: rgba($white, 1);
			/* border: 1px solid $black;
			box-shadow: none; */
		}

		.custom-radio .custom-control-input:checked~.custom-control-label::after {
			background-color: rgba($black, 1);
			/* border: 1px solid $black; */
			border-radius: 50%;
			/* background-image: none; */
		}

		.custom-control-input:focus~.custom-control-label::before {
			box-shadow: none;
			box-shadow: 0;
		}

		.input-group-append {
			margin: 0;
		}
	}
}


.modal-fullscreen {
	width: 100vw;
	max-width: 100vw;
	margin: 0;
	border-radius: 0;

	.modal-content {
		border-radius: 0;
		border: 0;
	}

	.modal-body {
		padding: 0;
	}

	.modal-header {
		border-radius: 0;
	}
}


/********** Carousel slide direction: up **********/

.slide-up {

	.carousel-item-next.carousel-item-left,
	.carousel-item-prev.carousel-item-right {
	  transform: translateX(0);

	  @supports (transform-style: preserve-3d) {
	    transform: translate3d(0, 0, 0);
	  }
	}

	.carousel-item-next,
	.active.carousel-item-right {
	  transform: translateY(100%);

	  @supports (transform-style: preserve-3d) {
	    transform: translate3d(0, -100%, 0);
	  }
	}

	.carousel-item-prev,
	.active.carousel-item-left {
	  transform: translateY(-100%);

	  @supports (transform-style: preserve-3d) {
	    transform: translate3d(0, -100%, 0);
	  }
	}
	.carousel-item-next.carousel-item-left,
	.carousel-item-prev.carousel-item-right {
	  transform: translateX(0);

	  @supports (transform-style: preserve-3d) {
	    transform: translate3d(0, 0, 0);
	  }
	}

	.carousel-item-next,
	.active.carousel-item-right {
	  transform: translateY(100%);

	  @supports (transform-style: preserve-3d) {
	    transform: translate3d(0, 100%, 0);
	  }
	}

	.carousel-item-prev,
	.active.carousel-item-left {
	  transform: translateY(-100%);

	  @supports (transform-style: preserve-3d) {
	    transform: translate3d(0, -100%, 0);
	  }
	}

}


/********** CSS for Chart legends **********/

.legend {
	display: inline;
	ul {
		display: inline;
		padding-left: 0;
		li {
			display: inline-block;
			font-size: 0.875em;

			&:not(:last-of-type) {
				margin-right: 1em;
			}

			span {
				display: inline-block;
				width: 10px;
				height: 10px;
				margin-right: 0.5em;
				border-radius: 50%;
			}
		}
	}
}



/********** Material Icons **********/

.material-icons {
	display: inline;
    vertical-align: middle;
}



/********** Tables **********/

table.table {
	font-size: 0.875em;
	border-color: $light-gray;

	&.table-hover {
		tbody {
			tr {

				&:hover {
					color: $white;
				}
			}
		}
	}

	thead {
		tr {
			th {
				border-color: $light-gray;
			}
		}
	}

	tbody {
		tr {
			th {
				border-color: $light-gray;
				font-weight: 400;
			}

			td {
				border-color: $light-gray;
			}

			&:first-of-type {
				th, td {
					border: 0;
				}
			}
		}
	}
}



/********** Users **********/

.user-name {
	line-height: 1;
}



/********** Progress Bars **********/

.progress {
	height: 4px;
	border-radius: 0.25rem;

	.progress-bar {
		height: 4px;
		border-radius: 0.25rem;
		width: 0;

		&.in-viewport {
			background-image: linear-gradient(90deg, rgba(theme-color-level(primary, 0), 1) 0%, rgba(theme-color-level(secondary, 0), 1) 100%);
			animation: progress 0.5s ease-in-out forwards;

			@keyframes progress {
				from {
					width: 0;
				}
				to {
					width: 100%;
				}
			}
		}
	}
}





/********** Calender grid **********/

.cal-grid {
	font-size: 0.875em;
	display: grid;
	grid-gap: 0;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-auto-rows: 1fr;
	grid-auto-columns: 1fr;
	grid-auto-flow: row dense;
	text-align: center;

	.cal-cell {
		padding: 0.5em 0;

		&:nth-of-type(7n+6),
		&:nth-of-type(7n) {
			color: rgba($dark-gray, 0.5);
		}

		span {
			background-color: $tertiary;
			border-radius: 50%;
			padding: 0.5em;
			color: $white;
		}
	}
}

.tooltip {

	.tooltip-inner {
		letter-spacing: 0.05em;
		line-height: 1.3;
		padding: 14px;
	}
}

